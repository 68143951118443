
new Vue({
    el: '#blog-list',
    data() {
        return {
            articles: null,
            lang: document.documentElement.lang
        }
    },
    mounted() {
        axios
            .get(`/api/blog`)
            .then(response => {
                this.articles = response.data;
                this.lang = document.documentElement.lang;
            })
            .catch(error => {
                console.log(error)
                this.articles = [];
            });
    }
});


new Vue({
    el: '#blog-main',
    data() {
        return {
            article: null,
            lang: document.documentElement.lang
        }
    },
    mounted() {
        axios
            .get(`/api/blog/main`)
            .then(response => {
                console.log(response);
                this.article = response.data;
                this.lang = document.documentElement.lang;
            })
            .catch(error => {
                console.log(error)
                this.article = {};
            });
    }
});
